import { BalloonGame, balloonSetting } from "./Game";
import { setEvent } from "./Platform";

/**
 * Generic Baloon class
 */
export class Balloon {
	positionX: number;
	positionY: number;
	color: string;
	speed: number;
	type = "normal";
	game: BalloonGame;
	el = null;
	balloonId = null;
	explosion = null;
	explosionTimout = null;
	health: number = 0;
	paused = false;
	setting: balloonSetting;
	nextSetting?: balloonSetting;
	state: "init" | "active" | "removed";
	constructor(game: BalloonGame, color: string, setting: balloonSetting) {
		this.game = game;
		this.positionX = this.generateRandomXPos();
		this.color = color;
		this.positionY = this.game.screenHeight;
		this.health = setting.health;

		this.setting = game.config.balloonSettings[color];

		this.speed =
			(Math.random() * (setting.maxSpeed - setting.minSpeed) +
				setting.minSpeed) *
			this.game.balloonSpeedMultiplier *
			(this.game.screenHeight / 570);
		this.type = "normal";
		this.el = null;
		this.balloonId = null;
		this.explosion = null;
		this.explosionTimout = null;
		this.state = "init";
	}

	/**
	 * allows the speed of the baloom to be multiplied
	 * @param {int} multiplier
	 */
	setMultipliedSpeed(multiplier) {
		this.speed *= multiplier;
	}

	updateBalloonPosition() {
		if (this.state !== "active") {
			return;
		}
		if (this.game.isPaused) {
			return;
		}

		this.positionY -= this.speed;
		this.el.style.top = this.positionY + "px";
		if (
			parseInt(this.el.style.top) <
			-this.el.clientHeight * (this.game.screenHeight / 570)
		) {
			this.destroy();
			this.game.removeLife();
		} else {
			this.intervalId = requestAnimationFrame(
				this.updateBalloonPosition.bind(this)
			);
		}
	}

	/**
	 * Make sure the balloon spawns on a random X position
	 */
	generateRandomXPos() {
		var canvasWidth = document.getElementById("canvas").offsetWidth;
		canvasWidth = canvasWidth - 106;

		return Math.floor(Math.random() * canvasWidth);
	}

	hit() {
		this.game.playSound("hit");

		this.health--;
		if (this.health > 0) {
			if (
				this.game.config.scoreType === "points" ||
				this.game.config.scoreType === "timeandpoints"
			) {
				this.game.score += this.setting.hitScore;
				this.game.updateScore(this.game.score);
			}

			return;
		}
		if (this.setting.next !== "none") {
			this.color = "balloon" + this.setting.next;
			if (
				this.game.config.scoreType === "points" ||
				this.game.config.scoreType === "timeandpoints"
			) {
				this.game.score += this.setting.popScore;
				this.game.updateScore(this.game.score);
			}

			this.setting = this.game.config.balloonSettings[this.color];
		} else {
			this.fullHit();
		}

		this.el.className = "balloon " + this.color;
	}

	fullHit() {
		if (
			this.game.config.scoreType === "points" ||
			this.game.config.scoreType === "timeandpoints"
		) {
			this.game.score += this.setting.popScore;
			this.game.updateScore(this.game.score);
		}

		if (this.state === "removed") {
			return;
		}
		this.state = "removed";
		this.explode();
	}

	/**
	 * Spawn the balloon as an element on the screen.
	 *
	 * @param {int} multiplier
	 */
	spawnOnCanvas(multiplier, balloonId) {
		this.balloonId = balloonId;
		this.el = document.createElement("div");
		this.el.className = "balloon " + this.color;
		this.el.id = balloonId;
		this.el.style.left = this.positionX + "px";
		this.el.style.top = this.positionY + "px";
		this.el.style.transform =
			"scale(" + this.game.config.balloonScale + ")";
		var balloon = this;

		if (this.game.debug) {
			const debug = document.createElement("div");
			debug.className = "debug";
			debug.innerHTML = `speed: ${this.speed}\nhealth: ${this.health}`;
			this.el.appendChild(debug);
		}

		// Handle mouse down OR touch start

		setEvent(this.el, (event) => {
			event.preventDefault();
			event.stopImmediatePropagation();
			balloon.hit();
		});

		this.game.canvasElement.appendChild(this.el);
		this.state = "active";
		// this.setMultipliedSpeed(multiplier);

		// if(this.game.debug){
		//     this.el.className += ' debug';
		// }
		requestAnimationFrame(this.updateBalloonPosition.bind(this));
	}

	/**
	 * Destroys the element.
	 */
	destroy() {
		if (this.state === "removed") {
			return;
		}
		this.state = "removed";
		this.game.numberOfBalloons--;
		this.game.canvasElement.removeChild(this.el);
	}

	explode() {
		this.game.playSound("explode");

		this.game.numberOfBalloons--;

		setTimeout(() => {
			if (this.el === null) {
				return;
			}
			const el = this.el as HTMLDivElement;
			el.className = "balloon";
			if (this.game.debug) {
				const debug = el.firstElementChild;
				el.removeChild(debug);
			}

			const explosion = this.el;

			let left = parseInt(explosion.style.left);
			let bottom = parseInt(explosion.style.top);

			explosion.style.left =
				left +
				this.game.config.explosionOffsetX *
					this.game.config.explosionScale +
				"px";
			explosion.style.top =
				bottom +
				this.game.config.explosionOffsetY *
					this.game.config.explosionScale +
				"px";

			explosion.className = "balloon explosion";
			explosion.style.opacity = this.game.config.explosionOpacity;
			explosion.style.transform =
				"scale(" +
				this.game.config.balloonScale *
					this.game.config.explosionScale +
				")";
		}, 2);
		setTimeout(() => {
			this.game.canvasElement.removeChild(this.el);
		}, this.game.config.explosionDuration + 2);
	}

	pause(state: boolean) {
		this.paused = state;
		if (!this.paused) {
			requestAnimationFrame(this.updateBalloonPosition.bind(this));
		}
	}
}
