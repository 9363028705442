import { BackgroundImage } from "./BackgroundImage";
import { Balloon } from "./Balloon";
import { printConfig } from "./main";
import { disableTouch, setEvent } from "./Platform";
import { Game, Platform } from "./GamePlatform";

interface IGameConfig {
	lives: number;

	startSpeed: number;
	levelUpMethod: "score" | "time" | "none";
	scoreType: "points" | "time" | "timeandpoints";

	speedIncrease: number;
	levelUpInterval: number;

	startSpawnTime: number;
	minimumSpawnTime: number;
	spawnIncrease: number;

	minNumberOfBalloons: number;
	maxNumberOfBalloons: number;
	balloonIncrease: number;

	path: string;
	balloonTypes: number;

	balloon1: string;
	balloon2: string;
	balloon3: string;
	balloon4: string;
	overlayColor: string;
	pauseMessage: string;
	balloonSettings: {
		balloon1: balloonSetting;
		balloon2: balloonSetting;
		balloon3: balloonSetting;
		balloon4: balloonSetting;
	};

	bonusPerMs: number;

	balloon1Sprite: string;
	balloon2Sprite: string;
	balloon3Sprite: string;
	balloon4Sprite: string;

	cloud1Sprite: string;
	cloud1Opacity: number;
	cloud2Sprite: string;
	cloud2Opacity: number;
	cloud3Sprite: string;
	cloud3Opacity: number;

	cloudSpawnTimeMin: number;
	cloudSpawnTimeMax: number;

	planeSprite: string;
	planeSpawnTimeMin: number;
	planeSpawnTimeMax: number;

	birdSprite: string;
	birdSpawnTimeMin: number;
	birdSpawnTimeMax: number;

	explosionSprite: string;
	explosionWidth: number;
	explosionHeight: number;
	explosionFrames: number;
	explosionDuration: number;
	explosionOpacity: number;
	explosionOffsetX: number;
	explosionOffsetY: number;

	lifeSprite: string;
	lifeLostSprite: string;
	lifeScale: number;

	splashSprite: string;
	splashScale: number;
	splashY: number;

	balloonScale: number;
	explosionScale: number;
	cloud1Scale: number;
	cloud2Scale: number;
	cloud3Scale: number;

	birdScale: number;
	planeScale: number;

	spawnSound?: string;
	hitSound?: string;
	explodeSound?: string;
	loseLifeSound?: string;

	scoreFont: string;
	scoreColor: string;
	scoreSize: number;

	pauseMessageFont: string;
	pauseMessageColor: string;
	pauseMessageSize: number;
}

const DefaultConfig = {
	balloonTypes: 4,

	startSpeed: 1,
	levelUpMethod: "time",
	speedIncrease: 0.02,
	levelUpInterval: 100,

	balloon1Sprite: "red.png",
	balloon2Sprite: "green.png",
	balloon3Sprite: "pink.png",
	balloon4Sprite: "orange.png",

	cloud1Sprite: "cloud1.png",
	cloud2Sprite: "cloud2.png",
	cloud3Sprite: "cloud3.png",

	planeSprite: "plane.png",
	birdSprite: "bird.png",
	explosionSprite: "explode.png",
	explosionDuration: 250,
	explosionOffsetX: 0,
	explosionOffsetY: 0,

	lifeSprite: "life.png",
	lifeLostSprite: "life-lost.png",
	lifeScale: 0.5,

	startSpawnTime: 1200, // ms
	minimumSpawnTime: 200, // ms
	spawnIncrease: 10,

	minNumberOfBalloons: 1,
	maxNumberOfBalloons: 8,
	balloonIncrease: 1,

	//minspeed,maxspeed,hitscore,health,changescore,next,popscore
	balloon1: "2,3,10,3,3,none ",

	balloon3: "3,3,1,5,0,green",
	balloon2: "4,5,20,1,3,none",
	balloon4: "5,5,30,1,3,none",

	balloonScale: 2,
	explosionScale: 2,
	cloud1Scale: 2,
	birdScale: 3,
	planeScale: 0.5,

	bonusPerMs: 0.01,

	path: "https://gamecdn.playenwin.com/wietse/balloon/default/",

	lives: 3, // not changeable without html changes
	overlayColor: "#FFFFFF",
	pauseMessage: "Spel gepauzeerd",

	scoreFont: "Bungee",
	scoreColor: "#000000",
	scoreSize: 30,

	pauseMessageColor: "#000000",
	pauseMessageFont: "Arial",
	pauseMessageSize: 50,

	birdSpawnTimeMax: 500,
	birdSpawnTimeMin: 500,

	planeSpawnTimeMax: 3000,
	planeSpawnTimeMin: 3000,

	cloudSpawnTimeMin: 1000,
	cloudSpawnTimeMax: 1500,

	scoreType: "timeandpoints",
} as IGameConfig;

type BackgroundTypes = "plane" | "cloud" | "bird";
export interface balloonSetting {
	next: nextType;
	hitScore: number;
	popScore: number;
	health: number;
	minSpeed: number;
	maxSpeed: number;
}
export type nextType = "red" | "green" | "orange" | "pink" | "none";
export type balloonType = "red" | "green" | "orange" | "pink";
export class BalloonGame implements Game {
	state: "init" | "ready" | "playing" | "gameover";
	debug: boolean;
	isPaused: boolean;
	balloonId: number;
	score: number;
	playElement: HTMLElement;
	scoreElement: HTMLElement;
	livesElement: HTMLElement;
	canvasElement: HTMLElement;
	lifeElements: any[];
	screenHeight: number;
	screenWidth: number;
	intervalId: any;
	spawnTime: number;
	maxNumberOfBalloons: number;
	balloonSpeedMultiplier: number;
	lives: number;
	lastCheckedInterval: number;
	lastcheckedScoreSpeed: number;
	scoreElem: any;
	config: IGameConfig;
	startTime: number;
	numberOfBalloons: number;

	private images: Array<HTMLImageElement> = [];
	private imageLoadCount = 0;
	private platform: Platform;
	constructor() {
		// printConfig(DefaultConfig);
		this.debug = false; // turn true if you wan to log
		this.isPaused = false;
		this.balloonId = null;
		this.score = null;
		this.playElement = document.getElementById("start-btn");
		this.scoreElement = document.getElementById("score-label");
		this.livesElement = document.getElementById("lives-container");
		this.canvasElement = document.getElementById("canvas");
		this.lifeElements = [];
		this.screenHeight = null;
		this.screenWidth = null;
		this.intervalId = null;
		this.spawnTime = null;
		this.balloonSpeedMultiplier = 1;
		this.lives = null;
		this.lastCheckedInterval = null;
		this.lastcheckedScoreSpeed = null;
		this.state = "init";

		document.getElementById("score-label")!.style.transition = "";

		document.getElementById("score-label")!.style.transform =
			"translateY(-20px) translateY(-100%)";

		document.getElementById("lives-container")!.style.transition = "";

		document.getElementById("lives-container")!.style.transform =
			"translateY(-20px) translateY(-100%)";

		this.platform = new Platform();

		disableTouch();
		this.loadConfig();

		this.setStyles();

		// Prepare game
		this.preload();
	}

	playSound(soundKey: string, loop: boolean = false) {
		const config = this.config as any;
		if (config[soundKey + "Sound"])
			this.platform.playSound(
				config.path + "/" + config[soundKey + "Sound"]
			);
	}

	drawLives() {
		this.livesElement.innerHTML = "";

		for (let i = 0; i < this.config.lives; i++) {
			let life = document.createElement("div");
			life.className = "life";
			life.style.transformOrigin = "top left";
			life.style.transform = "scale(" + this.config.lifeScale + ")";
			if (i + 1 > this.lives) {
				life.classList.add("lost");
			}
			this.livesElement.appendChild(life);
		}
	}

	/**
	 * Initialzie the game
	 */
	initGame() {
		this.balloonId = 0;
		this.isPaused = true;
		this.score = 0;
		this.timer = 0;
		this.spawnTime = this.config.startSpawnTime;
		this.numberOfBalloons = 0;
		this.maxNumberOfBalloons = this.config.minNumberOfBalloons;
		this.balloonSpeedMultiplier = this.config.startSpeed;
		this.lives = this.config.lives;
		this.lastCheckedInterval = 0;
		this.lastcheckedScoreSpeed = 0;
		this.screenHeight = document.getElementById("canvas").clientHeight;
		this.screenWidth = document.getElementById("canvas").clientWidth;
		this.scoreElem = document.getElementById("score-count");
		this.scoreElem.style.fontFamily = this.config.scoreFont;

		this.scoreElem.style.color = this.config.scoreColor;
		this.scoreElem.style.fontSize = this.config.scoreSize + "px";

		this.drawLives();

		if (this.debug) {
			console.log("__DEBUG MODE ENABLED__");
		} else {
			this.gameLog("__DEBUG MODE DISABLED__");
		}

		this.platform.init(this, this.config, () => {
			this.state = "ready";
		});

		if (this.debug) {
			top.window.addEventListener("keydown", (e: KeyboardEvent) => {
				if (e.code === "KeyP") {
					this.pause();
				}
			});
		}
		setEvent(
			document,
			this.handleClick.bind(this),
			this.handleTap.bind(this)
		);

		document.addEventListener("pointerup", () => {
			if (this.state !== "playing") {
				this.startGame();
			}
		});

		document.addEventListener(
			"blur",
			(e) => {
				this.pause();
				// Pause on blur?
			},
			{ passive: false }
		);

		window.addEventListener(
			"visibilitychange",
			(e) => {
				if (document.visibilityState === "hidden") {
					this.pause();
				}
			},
			{ passive: false }
		);

		// Safari
		document.addEventListener(
			"pagehide",
			(event) => {
				this.pause();
			},
			{ passive: false }
		);
	}
	private handleTap(e: TouchEvent) {
		e.preventDefault();
		e.stopPropagation();
		this.handleClickTap(
			e.changedTouches[0].clientX,
			e.changedTouches[0].clientY
		);
	}

	private handleClick(e: MouseEvent) {
		e.preventDefault();
		e.stopPropagation();
		this.handleClickTap(e.clientX, e.clientY);
	}

	private handleClickTap(x: number, y: number) {
		if (this.isPaused) {
			this.resume();
			return;
		}
	}

	pause() {
		if (this.isPaused) {
			return;
		}
		if (this.state !== "playing") {
			return;
		}
		document.getElementById("overlay").style.display = "block";
		this.balloons.forEach((balloon) => {
			balloon.pause(true);
		});
		this.bgElements.forEach((image) => {
			image.pause(true);
		});
		this.isPaused = true;
	}

	private resume() {
		document.getElementById("overlay").style.display = "none";
		this.balloons.forEach((balloon) => {
			balloon.pause(false);
		});
		this.bgElements.forEach((image) => {
			image.pause(false);
		});
		this.isPaused = false;
		window.setTimeout(() => {
			this.updateGame();
		}, this.spawnTime);
	}

	private loadConfig() {
		const urlParams = new URLSearchParams(window.location.search);
		this.config = DefaultConfig;

		// Patch settings
		if (urlParams.get("s")) {
			const settings = atob(urlParams.get("s"))
				.split("\n")
				.filter((s) => s.trim() !== "")
				.map((s) => {
					return {
						name: s.split("=")[0].trim(),
						value: s.split("=")[1].trim(),
					};
				});
			this.gameLog(settings);
			settings.forEach((s) => {
				if (!isNaN(+s.value)) {
					this.config[s.name] = +s.value;
				} else if (s.value === "true") {
					this.config[s.name] = true;
				} else if (s.value === "false") {
					this.config[s.name] = false;
				} else {
					this.config[s.name] = s.value;
				}
			});
		}

		const balloon4 = this.config.balloon4.split(",");
		const balloon2 = this.config.balloon2.split(",");
		const balloon3 = this.config.balloon3.split(",");
		const balloon1 = this.config.balloon1.split(",");

		//minspeed,maxspeed,hitscore,health,changescore,next,popscore
		this.config.balloonSettings = {
			balloon2: {
				minSpeed: +balloon2[0],
				maxSpeed: +balloon2[1],
				hitScore: +balloon2[2],
				popScore: +balloon2[4],
				next: balloon2[5] as nextType,
				health: +balloon2[3],
			},
			balloon1: {
				minSpeed: +balloon1[0],
				maxSpeed: +balloon1[1],
				hitScore: +balloon1[2],
				popScore: +balloon1[4],
				next: balloon1[5] as nextType,
				health: +balloon1[3],
			},
			balloon3: {
				minSpeed: +balloon3[0],
				maxSpeed: +balloon3[1],
				hitScore: +balloon3[2],
				popScore: +balloon3[4],
				next: balloon3[5] as nextType,
				health: +balloon3[3],
			},
			balloon4: {
				minSpeed: +balloon4[0],
				maxSpeed: +balloon4[1],
				hitScore: +balloon4[2],
				popScore: +balloon4[4],
				next: balloon4[5] as nextType,
				health: +balloon4[3],
			},
		};

		console.log("CONFIG", this.config);
	}

	private preload() {
		this.platform.preloadSounds(this.config);

		const imageList = [
			this.config.balloon1Sprite,
			this.config.balloon2Sprite,
			this.config.balloon3Sprite,
			this.config.balloon4Sprite,
			this.config.planeSprite,
			this.config.explosionSprite,
			this.config.birdSprite,
			this.config.cloud1Sprite,
			this.config.cloud2Sprite,
			this.config.cloud3Sprite,
			this.config.lifeSprite,
			this.config.lifeLostSprite,
			this.config.splashSprite,
		];
		// const cache = this.debug ? '?' + Date.now() : '';
		let i = 0;
		imageList.forEach((imagename) => {
			i++;
			const j = i;
			let img = new Image();
			img = new Image();
			img.src = this.config.path + imagename;
			img.onload = (e) => this.checkImages(j, imagename);
			img.onerror = (e) => this.checkImages(j, imagename);
			this.images.push(img);
		});
	}

	showSplash() {
		document.getElementById("splash").style.visibility = "visible";

		document.getElementById("score-label")!.style.transition = "";
		document.getElementById("score-label")!.style.transform =
			"translateY(-20px) translateY(-100%)";

		document.getElementById("lives-container")!.style.transition = "";
		document.getElementById("lives-container")!.style.transform =
			"translateY(-20px) translateY(-100%)";
	}

	checkImages(i, img) {
		this.imageLoadCount++;
		if (this.imageLoadCount === this.images.length) {
			console.log("ALL IMAGES LOADED ");
			this.initGame();
		}
	}

	private setStyles() {
		const sheet = document.createElement("style");
		const path = this.config.path;
		sheet.innerHTML = `
            .plane {background-image: url("${path}${this.config.planeSprite}")}
            .life {background-image: url("${path}${this.config.lifeSprite}")}
            .life.lost {background-image: url("${path}${
			this.config.lifeLostSprite
		}")}
            .balloon.explosion {background-image: url("${path}${
			this.config.explosionSprite
		}"); animation: explosion ${this.config.explosionDuration}ms steps(${
			this.config.explosionFrames
		}); width: ${this.config.explosionWidth}px; height: ${
			this.config.explosionHeight
		}px}
            .balloon.balloon1 {background-image: url("${path}${
			this.config.balloon1Sprite
		}")}
            .balloon.balloon2 {background-image: url("${path}${
			this.config.balloon2Sprite
		}")}
            .balloon.balloon3 {background-image: url("${path}${
			this.config.balloon3Sprite
		}")}
            .balloon.balloon4 {background-image: url("${path}${
			this.config.balloon4Sprite
		}")}

            .cloud.type1 {background-image: url("${path}${
			this.config.cloud1Sprite
		}")}
            .cloud.type2 {background-image: url("${path}${
			this.config.cloud2Sprite
		}")}
            .cloud.type3 {background-image: url("${path}${
			this.config.cloud3Sprite
		}")}
            .bird {background-image: url("${path}${this.config.birdSprite}")}
            .overlay {background-color: ${this.config.overlayColor}}

            .splashcontent {
                background-image: url("${path}${this.config.splashSprite}");
                background-position: 50% ${this.config.splashY}%;
                background-size: ${this.config.splashScale * 100}%;
            }


            @keyframes explosion {
                from { background-position: 0px; }
                to { background-position:-${
					this.config.explosionWidth * this.config.explosionFrames
				}px}
            }
        `;

		document.body.appendChild(sheet);

		const elmOverlay = document.getElementById("overlay");
		const elmOverlayContent = document.getElementById("overlayContent");

		elmOverlay.style.backgroundColor = this.config.overlayColor;
		elmOverlayContent.innerHTML = this.config.pauseMessage;
		elmOverlayContent.style.color = this.config.pauseMessageColor;
		elmOverlayContent.style.fontFamily = this.config.pauseMessageFont;
		elmOverlayContent.style.fontSize = this.config.pauseMessageSize + "px";

		if (this.debug) {
			console.log("test");
			document.getElementById("debugInfo").style.display = "block";
			document.getElementById("debugSpeed").innerHTML =
				this.balloonSpeedMultiplier?.toString() || "";
		}
	}

	private readyToPlay() {}

	public play() {}

	timeBonusLoop: any;

	startGame() {
		if (this.state !== "ready") {
			return;
		}

		document.getElementById("score-label")!.style.transform =
			"translateY(0)";
		document.getElementById("score-label")!.style.transition =
			"linear transform 200ms";

		document.getElementById("lives-container")!.style.transform =
			"translateY(0)";
		document.getElementById("lives-container")!.style.transition =
			"linear transform 200ms";

		document.getElementById("splash").style.visibility = "hidden";

		this.balloons = [];
		this.score = 0;
		this.lives = this.config.lives;
		this.spawnTime = this.config.startSpawnTime;
		this.balloonSpeedMultiplier = this.config.startSpeed;
		this.lastCheckedInterval = 0;
		this.lastcheckedScoreSpeed = 0;
		this.state = "playing";
		this.scoreElem.innerHTML = 0;
		this.lifeElements.forEach((e) => {
			e.classList.remove("lost");
		});

		if (this.config.bonusPerMs > 0 || this.config.levelUpMethod)
			this.timeBonusLoop = setInterval(() => {
				if (this.isPaused) {
					return;
				}
				this.timer += 10;
				if (
					this.config.scoreType === "time" ||
					this.config.scoreType === "timeandpoints"
				)
					this.score += this.config.bonusPerMs * 10;
				this.updateScore(this.score);
				this.checkForLevelUp();
			}, 10);

		this.gameLog(this.config.startSpawnTime);
		this.isPaused = false;
		this.updateGame();
		this.imageSpawner("cloud", 1, false, false);
		this.imageSpawner("cloud", 1, false, false);
		this.imageSpawner("cloud", 1, false, false);

		this.imageSpawner("plane", 1, false, false);
		this.imageSpawner("bird", 1, false, false);

		this.platform.gamestarted();

		// this.intervalId = window.setInterval(, );
		// this.pew.gamestarted();
	}

	updateScore(score) {
		this.score = score;
		this.checkForLevelUp();
		this.scoreElem.innerHTML = Math.round(score)
			.toString()
			.replace(/\B(?=(\d{3})+(?!\d))/g, ".");
	}
	private balloons: Array<Balloon> = [];
	/**
	 * The update function is based on spawnTime
	 */
	updateGame() {
		if (this.debug) {
			document.getElementById("debugSpeed").innerHTML =
				this.config.levelUpMethod +
					": increase=" +
					this.config.speedIncrease +
					": current speed:" +
					this.balloonSpeedMultiplier?.toString() || "";
			document.getElementById("spanSpeed").innerHTML =
				this.spawnTime?.toString() || "";
		}
		if (this.isPaused) {
			return;
		}
		this.gameLog("update" + this.spawnTime);

		// first check if the player is already dead or not
		this.checkifGameOver();

		// then see if the player needs to get a multieplier or not

		// check if we need to increase spawnspeed
		// this.checkForLevelUp();

		if (this.state !== "playing") {
			return;
		}

		// create a random balloon
		if (this.numberOfBalloons < this.maxNumberOfBalloons) {
			this.numberOfBalloons += 1;

			setTimeout(() => {
				let balloon = this.getRandomBalloon();

				// spawn the balloon on our 'canvas'
				balloon.spawnOnCanvas(
					this.balloonSpeedMultiplier,
					this.balloonId
				);
				this.gameLog("speed" + this.balloonSpeedMultiplier);
				this.balloons.push(balloon);
				this.balloonId++;
			}, this.spawnTime);
		}

		window.setTimeout(() => {
			this.updateGame();
		}, this.spawnTime);
	}

	levelUp() {
		this.spawnTime = this.spawnTime - this.config.spawnIncrease;
		this.spawnTime = Math.max(this.spawnTime, this.config.minimumSpawnTime);
		this.balloonSpeedMultiplier += this.config.speedIncrease;
		this.maxNumberOfBalloons += this.config.balloonIncrease;
		this.maxNumberOfBalloons = Math.min(
			this.maxNumberOfBalloons,
			this.config.maxNumberOfBalloons
		);
	}

	/**
	 * if the player has a score higher than 1000 the multiplier for the balloon speed wil increase by 0.5
	 */
	// checkForMultiplier(type: "") {

	//             if (this.score - this.lastCheckedScore > this.config.speedIncreaseInterval) {
	//                 this.lastCheckedScore = this.score;
	//                 this.balloonSpeedMultiplier += this.config.speedIncrease;
	//                 this.gameLog("Current balloonSpeed Multiplier increased to: " + this.balloonSpeedMultiplier);
	//             }
	//             break;
	//     }
	//     this.gameLog('speed' + this.balloonSpeedMultiplier);
	// }
	checkForLevelUp() {
		if (this.config.levelUpMethod === "none") {
			return;
		}
		if (this.config.levelUpMethod === "score") {
			if (
				this.score - this.lastCheckedInterval >
				this.config.levelUpInterval
			) {
				this.lastCheckedInterval = this.score;
				this.levelUp();
			}
		}
		if (this.config.levelUpMethod === "time") {
			if (
				this.timer - this.lastCheckedInterval >
				this.config.levelUpInterval
			) {
				this.lastCheckedInterval = this.timer;
				this.levelUp();
			}
		}
	}

	timer: number = 0;

	/**
	 * Checks if the player is game over or not.
	 */
	checkifGameOver() {
		if (this.state === "gameover") {
			return;
		}
		this.gameLog("Check gameover");
		if (this.lives <= 0) {
			this.gameover();
		}
	}

	gameover() {
		clearInterval(this.timeBonusLoop);
		this.platform.gameover(Math.round(this.score));
		this.state = "gameover";
		// this.balloons.forEach(balloon => {
		//     balloon.destroy();
		// })
	}

	restart() {
		this.showSplash();

		// Destroy old balloons
		this.balloons.forEach((balloon) => {
			balloon.destroy();
		});
		this.scoreElem.innerHTML = 0;
		this.lifeElements.forEach((e) => {
			e.classList.remove("lost");
		});
		this.initGame();
		this.state = "ready";
		this.platform.ready();
		this.bgElements.forEach((bg) => {
			bg.destroy();
		});
		this.bgElements = [];
	}

	/**
	 * Get a random baloon to spawn
	 */
	getRandomBalloon(): Balloon {
		const random = Math.random();
		const rand = Math.floor(random * this.config.balloonTypes);
		this.playSound("spawn");
		switch (rand) {
			case 0:
				return new Balloon(
					this,
					"balloon1",
					this.config.balloonSettings.balloon1
				);
			case 1:
				return new Balloon(
					this,
					"balloon2",
					this.config.balloonSettings.balloon2
				);
			case 2:
				return new Balloon(
					this,
					"balloon3",
					this.config.balloonSettings.balloon3
				);
			case 3:
				return new Balloon(
					this,
					"balloon4",
					this.config.balloonSettings.balloon4
				);
			default:
				return new Balloon(
					this,
					"green",
					this.config.balloonSettings.balloon1
				);
		}
	}

	/**
	 * Removes life from player
	 */
	removeLife() {
		if (this.lives > 0) {
			this.playSound("loseLife");
			this.lives--;
			this.drawLives();
			this.checkifGameOver();
		}
	}

	/**
	 * Sets the spawntime for mages on the background, bird plane cloud
	 * @param {string} type
	 * @param {int} amount
	 * @param {bool} directSpawn
	 */
	imageSpawner(type: BackgroundTypes, amount, directSpawn, diffentSpawnPos) {
		var spawnTime = 0;

		var spawnTimeMin = this.config[type + "SpawnTimeMin"];
		var spawnTimeMax = this.config[type + "SpawnTimeMax"];

		if (!directSpawn) {
			spawnTime =
				Math.floor(Math.random() * (spawnTimeMax - spawnTimeMin)) +
				spawnTimeMin;
		}
		for (let i = 0; i < amount; i++) {
			this.setImageTimout(
				type,
				amount,
				directSpawn,
				spawnTime,
				i,
				diffentSpawnPos
			);
		}
	}

	bgElements: Array<BackgroundImage> = [];

	/**
	 * Spawns the actual images
	 * @param {string} type
	 * @param {int} amount
	 * @param {boolean} directSpawn
	 * @param {int} spawnTime
	 * @param {int} i
	 */
	setImageTimout(
		type: BackgroundTypes,
		amount,
		directSpawn,
		spawnTime,
		i,
		diffentSpawnPos
	) {
		var game = this;
		// return;
		setTimeout(() => {
			if (this.state !== "playing") {
				return;
			}
			var spawnthis = null;
			switch (type) {
				case "cloud":
					if (amount == 1) {
						i = Math.floor(Math.random() * Math.floor(3));
					}
					spawnthis = new BackgroundImage(
						game,
						type,
						game.generateRandomNumber(
							0.3,
							1
						) /*Math.floor(Math.random() * Math.floor(2))+1*/,
						diffentSpawnPos,
						i
					);
					break;
				case "bird":
					spawnthis = new BackgroundImage(
						game,
						type,
						1,
						diffentSpawnPos
					);
					break;
				case "plane":
					spawnthis = new BackgroundImage(
						game,
						type,
						4,
						diffentSpawnPos
					);
					break;
			}

			spawnthis.spawnOnCanvas();
			this.bgElements.push(spawnthis);
		}, spawnTime);
	}

	generateRandomNumber(min, max) {
		return Math.random() * (max - min) + min;
	}

	endGame() {}

	gameLog(text) {
		if (this.debug) {
			console.log(text);
		}
	}
}
